import { useGetAuthData } from 'auth/hooks';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  useQueries,
} from 'react-query';
import { AxiosRequestHeaders, AxiosResponse, ResponseType } from 'axios';
import axiosApi from './axios';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface MutationPayload<TRouteParams, TBody> {
  params ?: TRouteParams;
  body ?: TBody;
}
export interface UseAuthMutationOptions<TRouteParams, TBody> {
  url: ((params: TRouteParams) => string) | string;
  method: HttpMethod;
  body?: TBody;
  mutationOptions ?: UseMutationOptions<
    AxiosResponse,
    unknown,
    MutationPayload<TRouteParams, TBody>,
    unknown
  >;
}

interface Params {
  [param : string] : string | number | boolean | undefined;
}

export const useAuthQuery = (
  url: string,
  refetchInterval?: number,
  queryOptions?: UseQueryOptions<any, unknown, any, string>,
  responseType ?: ResponseType,
  params ?: Params,
  additionalQueryKey ?: string[],
  customHeaders ?: Record<string, string>
) => {
  const { accessToken, userEmail } = useGetAuthData();
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const headers = {
    ...defaultHeaders,
    ...(customHeaders || {}),
  };

  return useQuery(
    additionalQueryKey ? url + ':::' + additionalQueryKey.toString() : url,
    ({ signal }) => {
      return axiosApi({
        url: `${process.env.REACT_APP_API_ENDPOINT}/${url}`,
        method: 'GET',
        headers,
        responseType: responseType,
        params: { ...(params ? params : {}) },
        signal,
      }).then((res) => res.data);
    },
    {
      ...queryOptions,
      refetchInterval: refetchInterval,
      enabled: !!accessToken && !!userEmail && (queryOptions?.enabled ?? true),
    },
  );
};

export const useAuthQueryFullRes = (
  url: string,
  refetchInterval?: number,
  queryOptions?: UseQueryOptions<any, unknown, any, string>,
  responseType ?: ResponseType,
  params ?: Params,
) => {
  const { accessToken, userEmail } = useGetAuthData();
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  return useQuery(
    url,
    () =>
      axiosApi({
        url: `${process.env.REACT_APP_API_ENDPOINT}/${url}`,
        method: 'GET',
        headers,
        responseType: responseType,
        params: { ...(params ? params : {}) },
      }).then((res) => res),
    {
      ...queryOptions,
      refetchInterval: refetchInterval,
      enabled: !!accessToken && !!userEmail && (queryOptions?.enabled ?? true),
    },
  );
};

export const useExternalQuery = (
  completeUrl: string,
  refetchInterval?: number,
  queryOptions?: UseQueryOptions<any, unknown, any, string>,
  responseType ?: ResponseType,
  params ?: Params,
  additionalHeaders ?: AxiosRequestHeaders,
) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...additionalHeaders,
  };

  return useQuery(
    completeUrl,
    () =>
      axiosApi({
        url: completeUrl,
        method: 'GET',
        headers,
        responseType: responseType,
        params: { ...(params ? params : {}) },
      }).then((res) => res.data),
    {
      ...queryOptions,
      refetchInterval: refetchInterval,
      enabled: queryOptions?.enabled ?? true,
    },
  );
};

export const useAuthQueries = (
  arrayOfUrls : string[],
  queryOptions ?: UseQueryOptions<unknown, unknown, unknown, string>,
) => {
  const { accessToken, userEmail } = useGetAuthData();

  const handleFetchQuery = (item: string) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    return axiosApi({
      url: `${process.env.REACT_APP_API_ENDPOINT}/${item}`,
      method: 'GET',
      headers,
    }).then((res) => res.data);
  };

  return useQueries(
    arrayOfUrls.map((item: string, index: number) => {
      return {
        queryKey: ['id', item],
        queryFn: () => handleFetchQuery(item),
        enabled: !!accessToken && !!userEmail && (queryOptions?.enabled ?? true),
      };
    }),
  );
};

export const useAuthMutation = <TRouteParams, TBody = object>({
  url,
  method,
  body,
  mutationOptions,
} : UseAuthMutationOptions<TRouteParams, TBody>) => {
  const { accessToken } = useGetAuthData();

  const axiosOptions = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
    data: body ? JSON.stringify(body) : undefined,
  };
  return useMutation(
    (payload : MutationPayload<TRouteParams, TBody>) =>
      axiosApi({
        url: `${process.env.REACT_APP_API_ENDPOINT}/${
          typeof url === 'string' ? url : url(payload.params as TRouteParams)
        }`,
        method: axiosOptions.method,
        headers: axiosOptions.headers,
        data: payload.body ?? body,
      }),
    mutationOptions,
  );
};
