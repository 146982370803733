import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Section } from '../components/Analytics/components/SearchBar';

export interface SalesFilters {
  plant : string[];
}

export interface PlantsAndUsersFilters {
  plant : string[];
  industry : string[];
  user : string[];
  role : string[];
  invitation : string[];
  fse : string[];
  region : string[];
  city : string[];
  state : string[];
  country : string[];
  distributor : string[];
}

export interface CartridgesFilters {
  cartridgepartnr : string[];
  cartridgesize : string[];
  cartridgegreasetype : string[];
  cartridgestatus : string[];
  plant : string[];
  'fill level' : string[];
  sensorserialnr : string[];
  cartridgesactionrequired : string[];
}

export interface UserActivityFilters {
  [key : string] : string[];
}

export interface PartsAndAssetsFilters {
  plant : string[];
  'asset type' : string[];
  'asset name' : string[];
  partnr : string[];
  partmanufacturer : string[];
  iscatalogued : string[];
}

export interface SensorsAndGatewaysFilters {
  gateway : string[];
  gatewaymac : string[];
  gatewaypn : string[];
  gatewaymodel : string[];
  gatewayconnectivity : string[];
  gatewaystatus : string[];
  sensorserialnr : string[];
  sensortype : string[];
  sensorstatus : string[];
  plant : string[];
  'sensor phase' : string[];
  warranty : string[];
  'estimated lifetime' : string[];
  'warranty type' : string[];
}

export interface DashboardState {
  viewType : string;
  typeOfAlert : string;
  modeAttention : string;
  modeInactive : string;
  measurement : string;
  expandPlantsAndUsers : boolean;
  expandUsersActivity : boolean;
  expandSensorsAndGateways : boolean;
  expandCartridges : boolean;
  expandPartsAndAssets : boolean;
  expandSales : boolean;
  analyticsFilterPlantsAndUsers : PlantsAndUsersFilters;
  analyticsPlantsAndUsersLastTag : string;
  analyticsSalesLastTag : string;
  analyticsFilterUserActivity : UserActivityFilters;
  analyticsUserActivityLastTag : string;
  analyticsFilterSensorsAndGateways : SensorsAndGatewaysFilters;
  analyticsSensorsAndGatewaysLastTag : string;
  plantsAndUsersContextToken ?: string;
  triggerPlantsAndUsersContextRefresh : boolean;
  triggerSalesContextRefresh : boolean;
  sensorsAndGatewaysContextToken ?: string;
  triggerSensorsAndGatewaysContextRefresh : boolean;
  triggerHistoryRead : Section | '';
  analyticsFilterCartridges : CartridgesFilters;
  triggerCartridgesContextRefresh : boolean;
  cartridgesContextToken ?: string;
  analyticsCartridgesLastTag : string;
  analyticsFiltersPartsAndAssets : PartsAndAssetsFilters;
  triggerPartsAndAssetsContextRefresh : boolean;
  partsAndAssetsContextToken ?: string;
  partsAndAssetsLastTag : string;
  salesContextToken ?: string;
  analyticsFilterSales : SalesFilters;
}

const initialState: DashboardState = {
  viewType: 'assets',
  typeOfAlert: 'all',
  modeAttention: 'weekly',
  modeInactive: 'weekly',
  measurement: 'newest',
  expandPlantsAndUsers: true,
  expandUsersActivity: true,
  expandSensorsAndGateways: true,
  expandCartridges: true,
  expandSales: true,
  analyticsFilterPlantsAndUsers: {
    plant: [],
    industry: [],
    user: [],
    role: [],
    invitation: [],
    fse: [],
    region: [],
    city: [],
    state: [],
    country: [],
    distributor: [],
  },
  analyticsFilterUserActivity: {},
  analyticsFilterSensorsAndGateways: {
    gateway: [],
    gatewaymac: [],
    gatewaypn: [],
    gatewaymodel: [],
    gatewayconnectivity: [],
    gatewaystatus: [],
    sensorserialnr: [],
    sensortype: [],
    sensorstatus: [],
    plant: [],
    'sensor phase': [],
    warranty: [],
    'estimated lifetime': [],
    'warranty type': [],
  },
  plantsAndUsersContextToken: undefined,
  triggerPlantsAndUsersContextRefresh: false,
  sensorsAndGatewaysContextToken: undefined,
  triggerSensorsAndGatewaysContextRefresh: false,
  triggerHistoryRead: '',
  analyticsPlantsAndUsersLastTag: '',
  analyticsUserActivityLastTag: '',
  analyticsSensorsAndGatewaysLastTag: '',
  analyticsFilterCartridges: {
    cartridgepartnr: [],
    cartridgesize: [],
    cartridgegreasetype: [],
    cartridgestatus: [],
    plant: [],
    'fill level': [],
    sensorserialnr: [],
    cartridgesactionrequired: [],
  },
  triggerCartridgesContextRefresh: false,
  analyticsCartridgesLastTag: '',
  partsAndAssetsContextToken: undefined,
  analyticsFiltersPartsAndAssets: {
    plant: [],
    'asset type': [],
    'asset name': [],
    partnr: [],
    partmanufacturer: [],
    iscatalogued: [],
  },
  triggerPartsAndAssetsContextRefresh: false,
  partsAndAssetsLastTag: '',
  expandPartsAndAssets: true,
  triggerSalesContextRefresh: false,
  analyticsFilterSales: {
    plant: [],
  },
  analyticsSalesLastTag: '',
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeViewType: (state, action : PayloadAction<string>) => {
      state.viewType = action.payload;
    },
    changeTypeOfAlert: (state, action : PayloadAction<string>) => {
      state.typeOfAlert = action.payload;
    },
    changeModeAttention: (state, action : PayloadAction<string>) => {
      state.modeAttention = action.payload;
    },
    changeModeInactive: (state, action : PayloadAction<string>) => {
      state.modeInactive = action.payload;
    },
    changeMeasurement: (state, action : PayloadAction<string>) => {
      state.measurement = action.payload;
    },
    setExpandPlantsAndUsers: (state, action : PayloadAction<boolean>) => {
      state.expandPlantsAndUsers = action.payload;
    },
    setExpandUsersActivity: (state, action : PayloadAction<boolean>) => {
      state.expandUsersActivity = action.payload;
    },
    setExpandSensorsAndGateways: (state, action : PayloadAction<boolean>) => {
      state.expandSensorsAndGateways = action.payload;
    },
    setExpandCartridges: (state, action : PayloadAction<boolean>) => {
      state.expandCartridges = action.payload;
    },
    setAnalyticsFilterPlantsAndUsers: (state, action : PayloadAction<PlantsAndUsersFilters>) => {
      state.analyticsFilterPlantsAndUsers = action.payload;
    },
    setAnalyticsFilterUserActivity: (state, action : PayloadAction<UserActivityFilters>) => {
      state.analyticsFilterUserActivity = action.payload;
    },
    setAnalyticsFilterSensorsAndGateways: (
      state,
      action : PayloadAction<SensorsAndGatewaysFilters>,
    ) => {
      state.analyticsFilterSensorsAndGateways = action.payload;
    },
    setPlantsAndUsersContextToken: (state, action : PayloadAction<string | undefined>) => {
      state.plantsAndUsersContextToken = action.payload;
    },
    setPlantsAndUsersTriggerContextRefresh: (state, action : PayloadAction<boolean>) => {
      state.triggerPlantsAndUsersContextRefresh = action.payload;
    },
    setSensorsAndGatewaysContextToken: (state, action : PayloadAction<string | undefined>) => {
      state.sensorsAndGatewaysContextToken = action.payload;
    },
    setTriggerSensorsAndGatewaysContextRefresh: (state, action : PayloadAction<boolean>) => {
      state.triggerSensorsAndGatewaysContextRefresh = action.payload;
    },
    setTriggerHistoryRead: (state, action : PayloadAction<Section | ''>) => {
      state.triggerHistoryRead = action.payload;
    },
    setAnalyticsPlantsAndUsersLastTag: (state, action : PayloadAction<string>) => {
      state.analyticsPlantsAndUsersLastTag = action.payload;
    },
    setAnalyticsUserActivityLastTag: (state, action : PayloadAction<string>) => {
      state.analyticsUserActivityLastTag = action.payload;
    },
    setAnalyticsSensorsAndGatewaysLastTag: (state, action : PayloadAction<string>) => {
      state.analyticsSensorsAndGatewaysLastTag = action.payload;
    },
    setAnalyticsFilterCartridges: (state, action) => {
      state.analyticsFilterCartridges = action.payload;
    },
    setTriggerCartridgesContextRefresh: (state, action) => {
      state.triggerCartridgesContextRefresh = action.payload;
    },
    setCartridgesContextToken: (state, action) => {
      state.cartridgesContextToken = action.payload;
    },
    setAnalyticsCartridgesLastTag: (state, action) => {
      state.analyticsCartridgesLastTag = action.payload;
    },
    setAnalyticsFilterPartsAndAssets: (state, action : PayloadAction<PartsAndAssetsFilters>) => {
      state.analyticsFiltersPartsAndAssets = action.payload;
    },
    setAnalyticsFilterSales: (state, action : PayloadAction<SalesFilters>) => {
      state.analyticsFilterSales = action.payload;
    },
    setTriggerPartsAndAssetsContextRefresh: (state, action : PayloadAction<boolean>) => {
      state.triggerPartsAndAssetsContextRefresh = action.payload;
    },
    setTriggerSalesContextRefresh: (state, action : PayloadAction<boolean>) => {
      state.triggerSalesContextRefresh = action.payload;
    },
    setPartsAndAssetsContextToken: (state, action : PayloadAction<string | undefined>) => {
      state.partsAndAssetsContextToken = action.payload;
    },
    setSalesContextToken: (state, action : PayloadAction<string | undefined>) => {
      state.salesContextToken = action.payload;
    },
    setAnalyticsPartsAndAssetsLastTag: (state, action : PayloadAction<string>) => {
      state.partsAndAssetsLastTag = action.payload;
    },
    setExpandPartsAndAssets: (state, action : PayloadAction<boolean>) => {
      state.expandPartsAndAssets = action.payload;
    },
    setExpandSales: (state, action : PayloadAction<boolean>) => {
      state.expandSales = action.payload;
    },
    setAnalyticsSalesLastTag: (state, action : PayloadAction<string>) => {
      state.analyticsSalesLastTag = action.payload;
    },
  },
});

export const {
  changeViewType,
  changeTypeOfAlert,
  changeModeAttention,
  changeModeInactive,
  changeMeasurement,
  setExpandPlantsAndUsers,
  setExpandSensorsAndGateways,
  setExpandUsersActivity,
  setExpandCartridges,
  setAnalyticsFilterPlantsAndUsers,
  setAnalyticsFilterUserActivity,
  setAnalyticsFilterSensorsAndGateways,
  setPlantsAndUsersContextToken,
  setPlantsAndUsersTriggerContextRefresh,
  setSensorsAndGatewaysContextToken,
  setTriggerSensorsAndGatewaysContextRefresh,
  setTriggerHistoryRead,
  setAnalyticsPlantsAndUsersLastTag,
  setAnalyticsSensorsAndGatewaysLastTag,
  setAnalyticsUserActivityLastTag,
  setAnalyticsFilterCartridges,
  setTriggerCartridgesContextRefresh,
  setCartridgesContextToken,
  setAnalyticsCartridgesLastTag,
  setAnalyticsFilterPartsAndAssets,
  setAnalyticsPartsAndAssetsLastTag,
  setPartsAndAssetsContextToken,
  setTriggerPartsAndAssetsContextRefresh,
  setExpandPartsAndAssets,
  setExpandSales,
  setTriggerSalesContextRefresh,
  setSalesContextToken,
  setAnalyticsFilterSales,
  setAnalyticsSalesLastTag,
} = dashboardSlice.actions;

export const selectViewType = (state: RootState) => state.dashboard.viewType;
export const selectTypeOfAlert = (state: RootState) => state.dashboard.typeOfAlert;
export const selectModeAttention = (state: RootState) => state.dashboard.modeAttention;
export const selectModeInactive = (state: RootState) => state.dashboard.modeInactive;
export const selectMeasurement = (state: RootState) => state.dashboard.measurement;
export const selectExpandPlantsAndUsers = (state : RootState) =>
  state.dashboard.expandPlantsAndUsers;
export const selectExpandUsersActivity = (state: RootState) => state.dashboard.expandUsersActivity;
export const selectExpandSensorsAndGateways = (state : RootState) =>
  state.dashboard.expandSensorsAndGateways;
export const selectExpandCartridges = (state : RootState) => state.dashboard.expandCartridges;
export const selectAnalyticsFilterPlanstAndUsers = (state : RootState) =>
  state.dashboard.analyticsFilterPlantsAndUsers;
export const selectAnalyticsFilterUserActivity = (state : RootState) =>
  state.dashboard.analyticsFilterUserActivity;
export const selectAnalyticsFilterSensorsAndGateways = (state : RootState) =>
  state.dashboard.analyticsFilterSensorsAndGateways;
export const selectPlantsAndUsersContextToken = (state : RootState) =>
  state.dashboard.plantsAndUsersContextToken;
export const selectTriggerPlantsAndUsersContextRefresh = (state : RootState) =>
  state.dashboard.triggerPlantsAndUsersContextRefresh;
export const selectSensorsAndGatewaysContextToken = (state : RootState) =>
  state.dashboard.sensorsAndGatewaysContextToken;
export const selectTriggerSensorsAndGatewaysContextRefresh = (state : RootState) =>
  state.dashboard.triggerSensorsAndGatewaysContextRefresh;
export const selectTriggerHistoryRead = (state: RootState) => state.dashboard.triggerHistoryRead;
export const selectAnalyticsPlantsAndUsersLastTag = (state : RootState) =>
  state.dashboard.analyticsPlantsAndUsersLastTag;
export const selectAnalyticsUserActivityLastTag = (state : RootState) =>
  state.dashboard.analyticsUserActivityLastTag;
export const selectAnalyticsSensorsAndGatewaysLastTag = (state : RootState) =>
  state.dashboard.analyticsSensorsAndGatewaysLastTag;
export const selectAnalyticsFilterCartridges = (state : RootState) =>
  state.dashboard.analyticsFilterCartridges;
export const selectTriggerCartridgesContextRefresh = (state : RootState) =>
  state.dashboard.triggerCartridgesContextRefresh;
export const selectCartridgesContextToken = (state : RootState) =>
  state.dashboard.cartridgesContextToken;
export const selectAnalyticsCartridgesLastTag = (state : RootState) =>
  state.dashboard.analyticsCartridgesLastTag;
export const selectAnalyticsFilterPartsAndAssets = (state : RootState) =>
  state.dashboard.analyticsFiltersPartsAndAssets;
export const selectTriggerPartsAndAssetsContextRefresh = (state : RootState) =>
  state.dashboard.triggerPartsAndAssetsContextRefresh;
export const selectTriggerSalesContextRefresh = (state : RootState) =>
  state.dashboard.triggerSalesContextRefresh;
export const selectPartsAndAssetsContextToken = (state : RootState) =>
  state.dashboard.partsAndAssetsContextToken;
export const selectSalesContextToken = (state : RootState) => state.dashboard.salesContextToken;
export const selectAnalyticsPartsAndAssetsLastTag = (state : RootState) =>
  state.dashboard.partsAndAssetsLastTag;
export const selectExpandPartsAndAssets = (state : RootState) =>
  state.dashboard.expandPartsAndAssets;
export const selectExpandSales = (state : RootState) => state.dashboard.expandSales;
export const selectAnalyticsFilterSales = (state : RootState) =>
  state.dashboard.analyticsFilterSales;
export const selectAnalyticsSalesLastTag = (state : RootState) =>
  state.dashboard.analyticsSalesLastTag;
export default dashboardSlice.reducer;
